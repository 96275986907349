<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md12>
        <v-card id="app_height" outlined>
          <v-layout pt-4 wrap justify-center>
            <v-flex text-center>
              <span style="font-size:16px;font-weight:600">Region Details</span>
            </v-flex>
          </v-layout>
          <v-layout pb-0 wrap justify-center>
            <v-flex pr-12 text-right>
              <router-link :to="'/EditRegion/'+id">
                <v-btn text color="#26af82">Edit</v-btn>
              </router-link>
            </v-flex>
          </v-layout>
          <v-layout pa-5>
            <v-flex>
              <v-card>
                <table class="logtable" width="100%">
                  <tr>
                    <th>Name</th>
                    <td>{{countryName}}</td>
                  </tr>
                  <tr>
                    <th>Dial Code</th>
                    <td>{{dialCode}}</td>
                  </tr>
                  <tr>
                    <th>Supporting Evidences</th>
                    <td>
                      <ul v-for="(item,i) in supportingEvidenceName" v-bind:key="i">
                        <li>{{item}}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>Terms and Conditions</th>
                    <td>{{termsAndConditions}}</td>
                  </tr>
                  <tr>
                    <th>Privacy Policy</th>
                    <td>{{privacyPolicy}}</td>
                  </tr>
                </table>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      showSnackBar: false,
      msg: null,
      timeout: 10000,
      appLoading: false,
      countryName: null,
      dialCode: null,
      supportingEvidenceName: [],
      termsAndConditions: null,
      privacyPolicy: null
    };
  },
  mounted() {
    this.appLoading = true;
    axios({
      method: "GET",
      url: "/country/get",
      headers: {
        "x-auth-token": localStorage.getItem("token")
      },
      params: {
        id: this.id
      }
    })
      .then(response => {
        this.appLoading = false;
        if (response.data.status) {
          var data = response.data.data;
          this.countryName = data.country;
          this.dialCode = data.dialcode;
          this.supportingEvidenceName = data.evidence;
          this.termsAndConditions = data.terms;
          this.privacyPolicy = data.privacy;
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      })
      .catch(err => {
        this.appLoading = false;
        this.msg = err.response.data.msg;
        this.showSnackBar = true;
      });
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}

.logtable,
.logtable th,
.logtable td {
  border: 1px solid black;
  border-collapse: collapse;
}
.logtable .logtable th,
.logtable td {
  padding: 5px;
  padding-left: 20px;
  text-align: left;
}
.logtable th {
  text-align: left;
  padding: 5px;
  padding-left: 50px;
  max-width: 80px;
}
</style>